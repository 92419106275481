import { OhipResponse } from "@/lib/types/patient";
import customFetch from "./customFetch";

export type OHIPHealthcardInfo = {
  healthcard_number: string | null;
  healthcard_versioncode: string | null;
};

const ohipValidate = async (healthCardNumber: string, healthCardVersionCode: string): Promise<OhipResponse> => {
  const { ohip } = await customFetch(`/ohip/validate`, {
    body: { healthcard_number: healthCardNumber, healthcard_versioncode: healthCardVersionCode } satisfies OHIPHealthcardInfo as OHIPHealthcardInfo,
    method: "POST",
  });
  return ohip;
};

const servicesAPI = { ohipValidate };

export default servicesAPI;
