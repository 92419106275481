import { Modal, Divider, Carousel } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import Icon from "@mdi/react";
import { mdiMessageOffOutline, mdiEmailOffOutline, mdiPhoneOff, mdiLock, mdiAccountEditOutline, mdiContentCopy, mdiMenuRight, mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { Popover as SCNPopover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Button as SCNButton } from "@/components/ui/button";
import { selectedAppointmentAtom, encounterNoteIdAtom, selectedPatientAtom, encounterNotesRefAtom } from "@/lib/states/globalStates";
import { EMRPatient } from "@/lib/types/patient";
import { ReactNode, useEffect, useRef, useState } from "react";
import { EMRAppointment } from "@/lib/types/appointment";
import { isEmpty, isNil } from "lodash";
import { LoggedInUser } from "./loggedInUser";
import { CarouselRef } from "antd/es/carousel";
import "./persistentHeader.scss";
import { ImageCropper } from "@/components/ImageCropper/ImageCropper";
import Image from "next/image";
import OHIPImage from "@/public/OHIP.svg";
import AlbertaImage from "@/public/Alberta.svg";
import PrivatePayImage from "@/public/Private pay.svg";
import BlueCrossImage from "@/public/Blue Cross.svg";
import OtherImage from "@/public/Other.svg";
import CheckmarkImage from "@/public/Checkmark.svg";
import XMarkImage from "@/public/Xmark.svg";
import CardImage from "@/public/Card.svg";
import CardBlackImage from "@/public/Card-Black.svg";
import { cn } from "@/lib/utils/cssUtils";
import physicianAPI from "@/api/physicianAPI";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import patientAPI from "@/api/patientAPI";
import { formatAddress, formatAge, formatDate, formatGender, formatHealthCard, formatName, sanitizeString } from "@/lib/utils/format";
import dataAPI from "@/api/dataAPI";
import { Textarea as SCNTextarea } from "@/components/ui/textarea";
import { Card as SCNCard, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "sonner";
import { EditPatientDetailsDialog } from "./editPatientDetailsDialog";
import useGlobalPatientMessageQuery from "@/lib/utils/hooks/useGlobalPatientMessageQuery";
import servicesAPI, { OHIPHealthcardInfo } from "@/api/servicesAPI";
import { OHIPPassCodes } from "@/lib/types/ohip";
const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";
interface PersistentHeaderProps {
  setNotesOpen: (notesOpen: boolean) => void;
}
export const PersistentHeader = ({
  setNotesOpen
}: PersistentHeaderProps) => {
  const queryClient = useQueryClient();
  const [selectedPatient, setSelectedPatient] = useAtom(selectedPatientAtom);
  const setEncounterNoteId = useSetAtom(encounterNoteIdAtom);
  const [doctorName, setDoctorName] = useState("");
  const [doctorBillingNumber, setDoctorBillingNumber] = useState("");
  const [showGlobalMessage, setShowGlobalMessage] = useState<boolean>(false);
  const [openEditPatientDetailsDialog, setOpenEditPatientDetailsDialog] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useAtom(selectedAppointmentAtom);
  const [patientGlobalMessage, setPatientGlobalMessage] = useState<string>("");
  const patientAlternateContactsInfoQueryObject = useQuery({
    queryKey: ["patientAlternateContactInfo", selectedPatient?.id],
    queryFn: () => patientAPI.getPatientsAlternateContactsInfoByRelationId(selectedPatient?.preferredContactId!),
    enabled: !isNil(selectedPatient?.preferredContactId)
  });
  const encounterNotesRef = useAtomValue(encounterNotesRefAtom);
  const updatePatientDataMutation = useMutation({
    mutationFn: (patientData: EMRPatient) => patientAPI.updatePatient(patientData),
    onSuccess: patientData => {
      queryClient.setQueryData(["selectedPatientData", selectedPatient?.id], patientData);
      queryClient.invalidateQueries({
        queryKey: ["selectedPatientGlobalMessage", selectedPatient?.id]
      });
      toast.success(`Patient's global message updated successfully.`);
    }
  });
  const {
    data: insuranceData
  } = useQuery({
    queryKey: ["insuranceData"],
    queryFn: dataAPI.getInsuranceData,
    staleTime: Infinity,
    placeholderData: []
  });
  const validatePatientHealthCardMutation = useMutation({
    mutationFn: ({
      healthCardNumber,
      healthCardVersionCode
    }: {
      healthCardNumber: string;
      healthCardVersionCode: string;
    }) => servicesAPI.ohipValidate(healthCardNumber, healthCardVersionCode),
    onSuccess: async data => {
      console.debug(`ohip data: `, data);
      const result = data.results?.at(0);
      if (isNil(result)) {
        toast.error(`Healthcard information is invalid.`);
        return;
      }
      const responseCode = !isNil(result?.responseCode) ? Number(result?.responseCode) : null;
      if (!isNil(responseCode) && OHIPPassCodes.includes(responseCode)) {
        toast.success(`Healthcard number is valid.`);
        return;
      }
      const responseMessage = <div className="flex flex-col gap-0.5">
          {(result?.responseDescription ?? "") !== "" && <div>{result?.responseDescription}</div>}
          {(result?.responseAction ?? "") !== "" && <div>{result?.responseAction}</div>}
        </div>;
      toast.error(responseMessage);
    }
  });
  const patientGlobalMessageQueryObject = useGlobalPatientMessageQuery(selectedPatient?.id ?? null);

  // const patientGlobalMessageQueryObject = useQuery({
  //   queryKey: ["selectedPatientGlobalMessage", selectedPatient?.id],
  //   queryFn: () => patientAPI.getPatientDataById(selectedPatient?.id!),
  //   select: (patientData) => patientData.globalMessage,
  //   enabled: !isNil(selectedPatient?.id),
  // });

  const onClear = async () => {
    if (!isNil(encounterNotesRef?.current?.saveEncounter)) {
      await encounterNotesRef.current.saveEncounter();
    }
    setSelectedPatient(null);
    setEncounterNoteId(null);
  };
  const getRefDoctor = async (physicianId: number) => {
    const physician = await physicianAPI.getAddressBookPhysicianById(physicianId);
    return physician;
  };
  useEffect(() => {
    if (selectedPatient?.id !== selectedAppointment.patientId) {
      setSelectedAppointment({
        notes: "",
        reason: "",
        appointmentId: null
      } as EMRAppointment);
    }
    if (!isNil(selectedPatient?.patientReferringDoctorId)) {
      getRefDoctor(selectedPatient.patientReferringDoctorId).then(physician => {
        setDoctorName(formatName(physician));
        setDoctorBillingNumber(physician.billingNumber ? physician.billingNumber : "");
      });
    }
  }, [selectedPatient]);
  const getInsuranceBackgroundColour = (insurer: string | null, opacity: string = "FF") => {
    if (isNil(selectedPatient)) {
      return "white";
    }
    const colour = insuranceData!.find(item => item.label === insurer)?.colour ?? insuranceData!.find(item => item.label === "No insurance and will pay directly")?.colour ?? "#72a957";
    return `${colour}${opacity}`;
  };
  useEffect(() => {
    setPatientGlobalMessage(patientGlobalMessageQueryObject.data ?? "");
  }, [patientGlobalMessageQueryObject.data]);
  return <>
      {!isNil(selectedPatient) && <div className="box-border flex h-5 justify-start pl-1" style={{
      backgroundColor: getInsuranceBackgroundColour(selectedPatient?.insurer ?? null)
    }}>
          <InsuranceIcon insurer={selectedPatient?.insurer ?? null} />
        </div>}
      {/* TODO: restyle this properly */}
      <div className="z-[100] flex bg-primary px-2.5 py-0" style={{
      minWidth: "calc(100vw - 90px)",
      borderBottom: "3px solid #f7f7f8"
    }}>
        <div className="min-w-0 flex-1 overflow-hidden">
          {isNil(selectedPatient) ? <div className="my-0 ml-5 mr-0 flex h-full items-center text-xl font-semibold leading-5 text-primary-fg-blue">No Patient Selected</div> :
        // TODO: adjust for screen size
        <div className="flex size-full justify-between gap-x-3 text-wrap">
              <div className="flex flex-col gap-0.5 p-1.5 text-sm">
                <div className="flex gap-x-1.5">
                  <div className="flex gap-1">
                    <span className="font-semibold">{`${formatName(selectedPatient, "last_first")} `}</span>
                    <span className="italic">{formatGender(selectedPatient.sex)}</span>
                  </div>
                  <SCNButton variant="ghost-no-hover" size="icon-fill" onClick={() => {
                // TODO: use the partially built version `/ui/copyButton`
                navigator.clipboard.writeText(selectedPatient.patientUid);
              }}>
                    <Icon className="text-button-01" path={mdiContentCopy} size="18px" />
                  </SCNButton>
                  <SCNButton variant="ghost-no-hover" size="icon-fill" onClick={() => setOpenEditPatientDetailsDialog(true)}>
                    <Icon className="text-button-01" path={mdiAccountEditOutline} size="18px" />
                  </SCNButton>
                  {(selectedPatient.dontCall ?? false) && <Icon path={mdiPhoneOff} size="18px" className="text-invalid-red" />}
                  {(selectedPatient.dontText ?? false) && <Icon path={mdiMessageOffOutline} size="18px" className="text-invalid-red" />}
                  {(selectedPatient.dontEmail ?? false) && <Icon path={mdiEmailOffOutline} size="18px" className="text-invalid-red" />}
                  {(selectedPatient.unsuccessfulIdentityVerificationAttempts ?? 0) >= 3 && <Icon path={mdiLock} size="18px" className="text-[#7E84A3]" />}
                </div>
                <div className="flex items-center gap-x-1 whitespace-nowrap">
                  <strong className="">DoB:</strong>
                  {!isNil(selectedPatient?.birth) ? <>{formatDate(selectedPatient.birth, {
                  format: "yyyy-MM-dd",
                  timeZone: "UTC"
                })}</> : ""}
                  {selectedPatient.birth ? ` (${formatAge(selectedPatient.birth)})` : ""}
                </div>
                <div>{formatAddress(selectedPatient)}</div>
              </div>
              <div className="flex flex-shrink-0 flex-col rounded-md p-1.5 text-sm" style={{
            backgroundColor: getInsuranceBackgroundColour(selectedPatient.insurer ?? "", "33")
          }}>
                <div className="flex items-center gap-x-1 whitespace-nowrap">
                  <strong className="">Insurer:</strong>
                  {selectedPatient.insurer ?? "No insurer selected"}
                </div>
                <div className="flex items-center gap-x-1 whitespace-nowrap">
                  <strong className="">Health Card:</strong>
                  <div className="flex">
                    <div>
                      {selectedPatient.healthCardNumber ? formatHealthCard(selectedPatient.healthCardNumber, selectedPatient.insurer ?? "") : ""}
                    </div>
                  </div>
                  <HealthCardStatus healthCardLastValidated={selectedPatient.healthCardLastValidated} healthCardValid={selectedPatient.healthCardValid ?? false} />
                </div>
                <div>
                  <HealthCardModal images={selectedPatient?.insurancePhotos ?? null} />
                  <SCNButton variant="ghost-no-hover" type="button" className="border-0 pl-0 text-button-01" disabled={isNil(selectedPatient?.healthcardNumber) && isNil(selectedPatient?.healthcardVersionCode) && selectedPatient?.insurer !== "Ontario"} onClick={() => {
                const hcn = sanitizeString(selectedPatient.healthCardNumber!, ["alphanumeric"]);
                validatePatientHealthCardMutation.mutate({
                  healthCardNumber: hcn.slice(0, 10),
                  healthCardVersionCode: hcn.slice(-2)
                });
              }} loading={validatePatientHealthCardMutation.isPending}>
                    Validate Card
                  </SCNButton>
                </div>
                <br />
                {/* See card button will go here */}
              </div>
              <div className="flex flex-col gap-0.5 p-1.5 text-sm">
                {!isNil(selectedPatient.preferredContactId) && <div className="flex items-center gap-0.5">
                    <div className="grid h-5 w-4 content-center"></div>
                    <div>
                      <div className="flex items-center gap-x-1 whitespace-nowrap">
                        <strong className="">Alternate Contact:</strong>
                        <span className="">
                          {patientAlternateContactsInfoQueryObject.data?.lastName ?? ""}
                          {!isNil(patientAlternateContactsInfoQueryObject.data?.lastName) && !isNil(patientAlternateContactsInfoQueryObject.data?.firstName) && ", "}
                          {patientAlternateContactsInfoQueryObject.data?.firstName ?? ""}
                        </span>
                        {!isNil(patientAlternateContactsInfoQueryObject.data?.relationship) && <span className="italic">({patientAlternateContactsInfoQueryObject.data?.relationship})</span>}
                      </div>
                    </div>
                  </div>}

                <div className="flex items-center gap-0.5">
                  <div className="grid h-5 w-4 content-center">
                    <Icon className={cn("text-yellow-400", (patientAlternateContactsInfoQueryObject.data?.preferredContactMethod ?? selectedPatient?.preferredContactMethod) !== "homePhoneCall" && "text-opacity-0")} path={mdiMenuRight} size={1} />
                  </div>
                  <div>
                    <div className={cn(`flex items-center gap-x-1 whitespace-nowrap`, selectedPatient?.dontCall && "text-[#ca2a29]")}>
                      <strong className="">Home Phone:</strong>
                      {!isNil(selectedPatient.preferredContactId) ? isEmpty(patientAlternateContactsInfoQueryObject.data?.homePhone ?? "") ? "Not entered" : patientAlternateContactsInfoQueryObject.data?.homePhone : isEmpty(selectedPatient.homePhone ?? "") ? "Not entered" : selectedPatient.homePhone}
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-0.5">
                  <div className="grid h-5 w-4 content-center">
                    <Icon className={cn("text-yellow-400", !["cellPhoneText", "cellPhoneCall"].includes(patientAlternateContactsInfoQueryObject.data?.preferredContactMethod ?? selectedPatient?.preferredContactMethod ?? "") && "text-opacity-0")} path={mdiMenuRight} size={1} />
                  </div>
                  <div>
                    <div className={cn(`flex items-center gap-x-1 whitespace-nowrap`, selectedPatient.dontCall && selectedPatient.dontText && "text-[#ca2a29]")}>
                      <strong className="">Cell Phone:</strong>
                      {!isNil(selectedPatient.preferredContactId) ? isEmpty(patientAlternateContactsInfoQueryObject.data?.cellPhone ?? "") ? "Not entered" : patientAlternateContactsInfoQueryObject.data?.cellPhone : isEmpty(selectedPatient.cellPhone ?? "") ? "Not entered" : selectedPatient.cellPhone}
                      {selectedPatient.cellPhone && selectedPatient.dontText && !selectedPatient.dontCall || (patientAlternateContactsInfoQueryObject.data?.preferredContactMethod ?? selectedPatient.preferredContactMethod) === "cellPhoneCall" && <span className="italic">(Call)</span>}
                      {selectedPatient.cellPhone && selectedPatient.dontCall && !selectedPatient.dontText || (patientAlternateContactsInfoQueryObject.data?.preferredContactMethod ?? selectedPatient.preferredContactMethod) === "cellPhoneText" && <span className="italic">(Text Message)</span>}
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-0.5">
                  <div className="grid h-5 w-4 content-center">
                    <Icon className={cn("w-3 text-yellow-400", (patientAlternateContactsInfoQueryObject.data?.preferredContactMethod ?? selectedPatient.preferredContactMethod) === "email" && "hidden")} path={mdiMenuRight} size={1} />
                  </div>
                  <div>
                    <div className={cn(`flex items-center gap-x-1 whitespace-nowrap`, (selectedPatient.dontEmail ?? false) && "text-invalid-red")}>
                      <strong className="">Email:</strong>
                      <div>
                        {!isNil(selectedPatient.preferredContactId) ? isEmpty(patientAlternateContactsInfoQueryObject.data?.email ?? "") ? "Not entered" : patientAlternateContactsInfoQueryObject.data?.email : isEmpty(selectedPatient.email ?? "") ? "Not entered" : selectedPatient.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-4 flex gap-x-3 p-1.5 text-sm">
                <div className="flex flex-col gap-0.5">
                  <div className="flex items-center gap-x-1 whitespace-normal">
                    <strong className="">Referring Provider:</strong>
                    {!isNil(selectedPatient.patientReferringDoctorId) ? doctorName : "N/A"}
                  </div>
                  <div className="flex items-center gap-x-1 whitespace-nowrap">
                    <strong className="">Billing:</strong>
                    {!isNil(selectedPatient.patientReferringDoctorId) ? doctorBillingNumber : "N/A"}
                  </div>
                  <div className="pt-1">
                    <SCNPopover open={showGlobalMessage} onOpenChange={setShowGlobalMessage}>
                      <PopoverContent className="p-0">
                        <SCNCard className="border-none">
                          <CardHeader className="p-4">
                            <CardTitle>Global Message</CardTitle>
                          </CardHeader>
                          <CardContent className={cn("p-4", "pt-0")}>
                            <SCNTextarea className="min-h-[200px] w-[400px]" value={patientGlobalMessage} onChange={e => setPatientGlobalMessage(e.target.value)} />
                          </CardContent>
                          <CardFooter>
                            <SCNButton onClick={v => updatePatientDataMutation.mutate({
                          ...selectedPatient!,
                          globalMessage: patientGlobalMessage
                        })}>
                              Save
                            </SCNButton>
                          </CardFooter>
                        </SCNCard>
                      </PopoverContent>
                      <PopoverTrigger asChild>
                        <SCNButton onClick={v => setShowGlobalMessage(prev => !prev)} className={cn("p-0 font-semibold", selectedPatient.globalMessage && "bg-[#bed5fc] pl-3 pr-1")} variant="ghost-no-hover">
                          <span>Global Message</span>
                          <Icon path={showGlobalMessage ? mdiMenuUp : mdiMenuDown} className={cn(selectedPatient.globalMessage ? "text-primary-fg-blue" : "text-primary-fg-body")} size={1.25} />
                        </SCNButton>
                      </PopoverTrigger>
                    </SCNPopover>
                  </div>
                </div>
                <Image className="h-fit cursor-pointer" src={`${basePath}/PatientClear.png`} alt="Clear patient" width={22} height={22} onClick={async () => {
              await onClear();
            }} />
              </div>
            </div>}
        </div>
        <div className="py-1.5 [text-wrap:nowrap]">
          <LoggedInUser setNotesOpen={setNotesOpen} data-sentry-element="LoggedInUser" data-sentry-source-file="persistentHeader.tsx" />
        </div>
        <EditPatientDetailsDialog openDialog={openEditPatientDetailsDialog} setOpenDialog={setOpenEditPatientDetailsDialog} data-sentry-element="EditPatientDetailsDialog" data-sentry-source-file="persistentHeader.tsx" />
      </div>
    </>;
};
interface HealthCardModalProps {
  images: string[] | null;
}
function HealthCardModal({
  images
}: HealthCardModalProps) {
  const carouselRef = useRef<CarouselRef>(null);
  const [healthCardModalVisible, setHealthCardModalVisible] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [colour, setColour] = useState("black");
  const [cursor, setCursor] = useState("default");
  const [text, setText] = useState("No Card");
  useEffect(() => {
    if (images && images.length > 0) {
      setColour("#0040ff");
      setCursor("pointer");
      setText("View Card");
    } else {
      setColour("black");
      setCursor("default");
      setText("No Card");
    }
  }, [images]);
  return <>
      {healthCardModalVisible && images && <Modal className="patient-persist-healthcard-carousel" open={healthCardModalVisible} onCancel={() => {
      setCarouselIndex(0);
      setHealthCardModalVisible(false);
    }} title={"Health Card"} footer={null}
    // width="40vw"
    centered style={{
      borderRadius: "15px",
      overflow: "hidden"
    }}>
          <Carousel style={{
        margin: "15px"
      }} ref={carouselRef} dots={false} afterChange={currentSlide => {
        setCarouselIndex(currentSlide);
      }} waitForAnimate={false} arrows>
            {images.map((image: string, index: number) => {
          return <ImageCropper height="40vh" key={`${index}`} src={image} preview />;
        })}
          </Carousel>
          <Divider />
          <div className="preview-images">
            {images.map((image, index) => <ImageCropper height="11vh" width="15vh" key={`${index}`} src={image} alt={`Insurance Photo ${index + 1}`} style={{
          borderRadius: "10px",
          border: carouselIndex === index ? "2px solid #0058f8" : "2px solid transparent",
          margin: "5px",
          cursor: "pointer"
        }} onClick={() => carouselRef.current?.goTo(index)} preview={false} />)}
          </div>
        </Modal>}
      {<div className="flex items-center gap-x-2" style={{
      cursor,
      color: colour
    }} onClick={() => {
      if ((images ?? []).length > 0) {
        setHealthCardModalVisible(true);
      }
    }}>
          {text}
          <Image src={(images ?? []).length > 0 ? CardImage : CardBlackImage} className="h-6 w-5" alt="Card Icon" />
        </div>}
    </>;
}
interface HealthCardStatusProps {
  healthCardLastValidated?: Date | null;
  healthCardValid?: boolean | null;
}
const HealthCardStatus = ({
  healthCardLastValidated,
  healthCardValid = false
}: HealthCardStatusProps) => {
  if (!isNil(healthCardLastValidated)) {
    if (healthCardValid) {
      return <SCNTooltip>
          <TooltipTrigger>
            <Image className="icon h-4 pl-0.5" src={CheckmarkImage} alt="Checkmark" />
          </TooltipTrigger>
          <TooltipContent side="right" className="bg-valid-green" arrowClassName="fill-valid-green">
            Card valid
          </TooltipContent>
        </SCNTooltip>;
    }
    return <SCNTooltip>
        <TooltipTrigger>
          <Image className="icon h-4 pl-0.5" src={XMarkImage} alt="Xmark" />
        </TooltipTrigger>
        <TooltipContent side="right" className="bg-invalid-red" arrowClassName="fill-invalid-red">
          Card invalid
        </TooltipContent>
      </SCNTooltip>;
  }
  return <></>;
};
interface InsuranceIconProps {
  insurer: string | null;
}
const InsuranceIcon = ({
  insurer
}: InsuranceIconProps): ReactNode => {
  switch (insurer) {
    case "Ontario":
      return <Image className="h-5 w-3" src={OHIPImage} alt="OHIP" />;
    case "Alberta":
      return <Image className="h-5 w-3" src={AlbertaImage} alt="Alberta" />;
    case "Medaview Blue Cross":
      return <Image className="h-5 w-3" src={BlueCrossImage} alt="Medavie Blue Cross" />;
    case "Private or other insurance":
    case "No insurance and will pay directly":
      return <Image className="h-5 w-3" src={PrivatePayImage} alt="Private pay" />;
    case "British Columbia":
    case "Manitoba":
    case "New Brunswick":
    case "Newfoundland and Labrador":
    case "Northwest Territories":
    case "Nova Scotia":
    case "Nunavut":
    case "Prince Edward Island":
    case "Quebec":
    case "Saskatchewan":
    case "Yukon":
      return <Image className="h-5 w-3" src={OtherImage} alt="Other Province/Territory" />;
    default:
      return <Image className="h-5 w-3" src={PrivatePayImage} alt="Private pay" />;
  }
};