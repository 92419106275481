import { Matcher } from "react-day-picker";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Button as SCNButton } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover as SCNPopover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils/cssUtils";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import { formatDate } from "@/lib/utils/format";
import { ClassValue } from "clsx";
import { TZDateMini } from "@date-fns/tz";
import { useMemo } from "react";

// TODO: create a style variant like input, esp for variant `form`

interface DatePickerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  date: Date | undefined | null;
  onDateChange?: (value: Date) => void;
  selectorContainerClassName?: ClassValue;
  calendarPopoverContainerClassName?: ClassValue;
  calendarInnerContainerClassName?: ClassValue;
  selectDatePlaceholderText?: string;
  showYearNavigation?: boolean;
  showDecadeNavigation?: boolean;
  selectableDates?: boolean;
  disabled?: boolean;
  disabledDates?: Matcher | Matcher[] | undefined;
  usePortal?: boolean;
  closeOnSelect?: boolean;
  timeZone?: string;
}
const DatePicker = ({
  open: openProp,
  onOpenChange,
  date,
  onDateChange,
  selectorContainerClassName,
  calendarPopoverContainerClassName,
  calendarInnerContainerClassName,
  selectDatePlaceholderText,
  showYearNavigation,
  showDecadeNavigation,
  selectableDates,
  disabled,
  disabledDates,
  usePortal = true,
  closeOnSelect = true,
  timeZone
}: DatePickerProps) => {
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: false,
    onChange: onOpenChange
  });
  const convertedDate = useMemo(() => date ? new TZDateMini(date, timeZone) : undefined, [date, timeZone]);
  return <SCNPopover open={open} onOpenChange={setOpen} data-sentry-element="SCNPopover" data-sentry-component="DatePicker" data-sentry-source-file="datePicker.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="datePicker.tsx">
        <SCNButton variant="outline" className={cn("w-[140px] justify-between text-left font-normal hover:ring-1 disabled:hover:ring-0", !date && "text-placeholder-fg", open && "ring-1", selectorContainerClassName)} disabled={disabled} data-sentry-element="SCNButton" data-sentry-source-file="datePicker.tsx">
          {date ? formatDate(date, {
          format: "yyyy-MM-dd",
          timeZone
        }) : <span>{selectDatePlaceholderText ?? `Pick a date`}</span>}
          <CalendarIcon className="size-4" data-sentry-element="CalendarIcon" data-sentry-source-file="datePicker.tsx" />
        </SCNButton>
      </PopoverTrigger>
      <PopoverContent className={cn("w-auto p-0", calendarPopoverContainerClassName)} align="start" disableArrow usePortal={usePortal} onPointerDownOutside={e => {
      e.stopImmediatePropagation();
    }} data-sentry-element="PopoverContent" data-sentry-source-file="datePicker.tsx">
        <Calendar className={cn("p-2", {
        "px-0": showYearNavigation && showDecadeNavigation || selectableDates
      }, calendarInnerContainerClassName)} mode="single" selected={convertedDate} defaultMonth={convertedDate} showYearNavigation={showYearNavigation} showDecadeNavigation={showDecadeNavigation} selectableDates={selectableDates} disabled={disabledDates} onSelect={e => {
        if (onDateChange && e) {
          onDateChange(new TZDateMini(e, timeZone));
          closeOnSelect && setOpen(false);
        }
      }} timeZone={timeZone}
      // initialFocus
      data-sentry-element="Calendar" data-sentry-source-file="datePicker.tsx" />
        {onDateChange && <SCNButton variant="ghost" className="border-t-solid flex h-9 w-full items-center justify-center rounded-t-none border-t border-gray-200" onClick={() => {
        onDateChange(new TZDateMini(new Date(), timeZone));
        closeOnSelect && setOpen(false);
      }}>
            Today
          </SCNButton>}
      </PopoverContent>
    </SCNPopover>;
};
DatePicker.displayName = "DatePicker";
export { DatePicker };