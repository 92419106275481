import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { selectedPatientAtom } from "@/lib/states/globalStates";
import { useBeforeUnload } from "@/lib/utils/useBeforeUnload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { cn } from "@/lib/utils/cssUtils";
import { Textarea as SCNTextarea } from "@/components/ui/textarea";
import { Button as SCNButton } from "@/components/ui/button";
import { SaveTaskNote } from "@/lib/types/taskNote";
import tasksAPI from "@/api/tasksAPI";
import { isNil } from "lodash";
import { formatName } from "@/lib/utils/format";
interface CreateTaskNoteProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
export const CreateTaskNote = ({
  isOpen,
  setOpen
}: CreateTaskNoteProps) => {
  const queryClient = useQueryClient();
  const [selectedPatient] = useAtom(selectedPatientAtom);
  const [typedDoctorNote, setTypedDoctorNote] = useState<string>("");
  const [isPageDirty, setIsPageDirty] = useState<boolean>(false);
  const saveDoctorTaskNoteMutation = useMutation({
    mutationFn: ({
      patientId,
      taskNote
    }: SaveTaskNote) => tasksAPI.saveDoctorTaskNote({
      patientId,
      taskNote
    }),
    onSuccess: () => {
      localStorage.setItem("typed_doctor_note", "");
      setTypedDoctorNote("");
      setOpen(false);
      toast.success("Task note saved");
      setIsPageDirty(false);
      queryClient.invalidateQueries({
        queryKey: ["tasks"]
      });
    },
    onError: () => {
      toast.error(`Task note was not saved due to error`);
    }
  });
  useEffect(() => {
    setTypedDoctorNote(localStorage.getItem("typed_doctor_note") as string);
  }, []);
  return <>
      {useBeforeUnload(isPageDirty)}
      {!isOpen ? <></> : <div className="fixed bottom-[30px] right-[30px] z-[300]">
          <div className={cn(`h-[400px] w-[500px] border border-solid border-gray-700 bg-primary p-4`, `border-t-[3px] border-t-[#ffb43c] pt-6`)}>
            <div className="absolute right-4 top-4">
              <SCNButton className="size-7 rounded-full border-[#4096ff] text-[#4096ff]" onClick={() => {
            if (isPageDirty && window.confirm("Are you sure you want to close the task editor? You have unsaved changes.")) {
              localStorage.setItem("typed_doctor_note", "");
              setTypedDoctorNote("");
              setOpen(false);
              setIsPageDirty(false);
            } else if (!isPageDirty) {
              setOpen(false);
            }
          }} variant="outline">
                X
              </SCNButton>
            </div>
            <div className="text-3xl font-bold">Create Task Note</div>
            <div className="pb-4 text-sm">{isNil(selectedPatient) ? `General Note` : `Patient: ${formatName(selectedPatient)}`}</div>
            <SCNTextarea className="flex-1" rows={10} placeholder="Type your task note here..." value={typedDoctorNote ?? ""} onChange={e => {
          setIsPageDirty(true);
          localStorage.setItem("typed_doctor_note", e.target.value);
          setTypedDoctorNote(e.target.value);
        }} />
            <div className="pt-4">
              <SCNButton onClick={() => {
            if ((typedDoctorNote ?? "") === "") {
              toast.info(`Task note is empty so nothing is saved`);
              return;
            }
            const payload: SaveTaskNote = {
              taskNote: typedDoctorNote!,
              ...(!isNil(selectedPatient?.id) && {
                patientId: selectedPatient.id
              })
            };
            saveDoctorTaskNoteMutation.mutate(payload);
          }}>
                Save
              </SCNButton>
            </div>
          </div>
        </div>}
    </>;
};