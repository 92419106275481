"use client";

import { Chevron, DayPicker, MonthCaptionProps, useDayPicker } from "react-day-picker";
import { buttonVariants, Button as SCNButton } from "@/components/ui/button";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";
import { addMonths } from "date-fns";
import { formatDate } from "@/lib/utils/format";
import Icon from "@mdi/react";
import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight, mdiChevronTripleLeft, mdiChevronTripleRight } from "@mdi/js";
import { Combobox, ComboItem } from "./combobox";
import { TZDateMini } from "@date-fns/tz";
const months = [{
  name: "January",
  value: "0"
}, {
  name: "February",
  value: "1"
}, {
  name: "March",
  value: "2"
}, {
  name: "April",
  value: "3"
}, {
  name: "May",
  value: "4"
}, {
  name: "June",
  value: "5"
}, {
  name: "July",
  value: "6"
}, {
  name: "August",
  value: "7"
}, {
  name: "September",
  value: "8"
}, {
  name: "October",
  value: "9"
}, {
  name: "November",
  value: "10"
}, {
  name: "December",
  value: "11"
}] satisfies ComboItem[] as ComboItem[];
const years = Array.from({
  length: 100
}, (_, index) => index + 1935).map(year => ({
  name: String(year),
  value: String(year)
})) satisfies ComboItem[] as ComboItem[];
interface CustomCalendarHeaderProps extends MonthCaptionProps {
  showYearNavigation?: boolean;
  showDecadeNavigation?: boolean;
  selectableDates?: boolean;
  timeZone?: string;
}
const CustomCalendarHeader = ({
  showYearNavigation = false,
  showDecadeNavigation = false,
  selectableDates = false,
  calendarMonth,
  timeZone,
  ...props
}: CustomCalendarHeaderProps) => {
  // TODO: if we want to do date range we need to move the "single" mode up to custom props
  const {
    goToMonth,
    nextMonth,
    previousMonth
  } = useDayPicker();
  return <div className="flex w-full items-center justify-between gap-2" data-sentry-component="CustomCalendarHeader" data-sentry-source-file="calendar.tsx">
      <div className="flex items-center gap-1.5">
        {showDecadeNavigation && <SCNButton variant="outline" size="smaller-icon" onClick={() => previousMonth && goToMonth(addMonths(previousMonth, -(9 * 12 + 11)))}>
            <Icon path={mdiChevronTripleLeft} size={0.75} />
          </SCNButton>}
        {showYearNavigation && <SCNButton variant="outline" size="smaller-icon" onClick={() => previousMonth && goToMonth(addMonths(previousMonth, -11))}>
            <Icon path={mdiChevronDoubleLeft} size={0.75} />
          </SCNButton>}
        <SCNButton variant="outline" size="smaller-icon" onClick={() => previousMonth && goToMonth(previousMonth)} data-sentry-element="SCNButton" data-sentry-source-file="calendar.tsx">
          <Icon path={mdiChevronLeft} size={0.75} data-sentry-element="Icon" data-sentry-source-file="calendar.tsx" />
        </SCNButton>
      </div>
      {selectableDates ? <>
          <Combobox containerClassName="w-[100px]" popoverTriggerClassName="gap-0 pr-3" popoverTriggerTextClassName="flex justify-center" popoverContentClassName="w-[104px]" disableChevron data={months} currentValue={calendarMonth.date.getMonth().toString()} onSelect={value => {
        const newDate = new TZDateMini(calendarMonth.date, timeZone);
        newDate.setMonth(parseInt(value, 10));
        goToMonth(newDate);
      }} popoverContentUsePortal={false} />

          <Combobox containerClassName="w-[64px]" popoverTriggerClassName="gap-0 pr-3" popoverTriggerTextClassName="flex justify-center" popoverContentClassName="w-[72px]" disableChevron data={years} currentValue={calendarMonth.date.getFullYear().toString()} onSelect={value => {
        const newDate = new TZDateMini(calendarMonth.date, timeZone);
        newDate.setFullYear(parseInt(value, 10));
        goToMonth(newDate);
      }} popoverContentUsePortal={false} />
        </> : <div className="text-sm font-medium">{formatDate(calendarMonth.date, {
        format: "MMM yyyy",
        timeZone
      })}</div>}
      <div className="flex items-center gap-1.5">
        <SCNButton variant="outline" size="smaller-icon" onClick={() => nextMonth && goToMonth(nextMonth)} data-sentry-element="SCNButton" data-sentry-source-file="calendar.tsx">
          <Icon path={mdiChevronRight} size={0.75} data-sentry-element="Icon" data-sentry-source-file="calendar.tsx" />
        </SCNButton>
        {showYearNavigation && <SCNButton variant="outline" size="smaller-icon" onClick={() => nextMonth && goToMonth(addMonths(nextMonth, 11))}>
            <Icon path={mdiChevronDoubleRight} size={0.75} />
          </SCNButton>}
        {showDecadeNavigation && <SCNButton variant="outline" size="smaller-icon" onClick={() => nextMonth && goToMonth(addMonths(nextMonth, 9 * 12 + 11))}>
            <Icon path={mdiChevronTripleRight} size={0.75} />
          </SCNButton>}
      </div>
    </div>;
};
type CalendarProps = ComponentProps<typeof DayPicker>;
type CustomCalendarProps = CalendarProps & {
  showYearNavigation?: boolean;
  showDecadeNavigation?: boolean;
  selectableDates?: boolean;
};
const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  showYearNavigation = false,
  showDecadeNavigation = false,
  selectableDates = false,
  timeZone,
  ...props
}: CustomCalendarProps) => {
  return <DayPicker showOutsideDays={showOutsideDays} className={cn("p-3", className)} classNames={{
    months: "flex flex-col space-y-4 sm:space-y-0",
    month: "mx-4 flex flex-col gap-3",
    month_caption: "flex justify-center pt-1 relative items-center",
    caption_label: "text-sm font-medium",
    nav: "hidden",
    button_previous: "invisible",
    button_next: "invisible",
    month_grid: "w-full border-collapse space-y-1 grid justify-center",
    weekdays: "flex",
    weekday: "text-placeholder-fg rounded-md w-8 font-normal text-[0.8rem]",
    week: "flex w-full mt-2",
    day: cn("relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md", props.mode === "range" ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md" : "[&:has([aria-selected])]:rounded-md"),
    day_button: cn(buttonVariants({
      variant: "ghost"
    }), "size-8 p-0 font-normal aria-selected:opacity-100"),
    range_start: "day-range-start",
    range_end: "day-range-end",
    selected: "bg-blue-200 text-primary-fg-body hover:bg-gray-300 hover:text-primary-fg-body focus:bg-blue-200 focus:text-primary-fg-body",
    today: "text-accent-foreground border rounded",
    outside: "day-outside text-placeholder-fg opacity-50  aria-selected:bg-accent/50 aria-selected:text-placeholder-fg aria-selected:opacity-30",
    disabled: "text-placeholder-fg opacity-50",
    range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
    hidden: "invisible",
    ...classNames
  }} components={{
    Chevron: props => <Chevron {...props} disabled />,
    MonthCaption: props => <CustomCalendarHeader {...props} showYearNavigation={showYearNavigation} showDecadeNavigation={showDecadeNavigation} selectableDates={selectableDates} timeZone={timeZone} />
  }} {...props} data-sentry-element="DayPicker" data-sentry-component="Calendar" data-sentry-source-file="calendar.tsx" />;
};
Calendar.displayName = "Calendar";
export { Calendar };