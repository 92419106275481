import {
  format as formatFNS,
  isFuture,
  subYears,
  isAfter,
  subHours,
  subDays,
  subWeeks,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  subMonths,
  differenceInYears,
  addMinutes,
} from "date-fns";
import { queryClient } from "@/lib/layouts/queryLayout";
import { InsuranceData, userProvinceMap } from "@/lib/types/constants";
import { PractitionerType } from "@/lib/types/physician";
import { TZDateMini } from "@date-fns/tz";

const CHARACTER_SETS = {
  alpha: "A-Za-z",
  numeric: "0-9",
  alphanumeric: "A-Za-z0-9",
};

export const formatPostalZipCode = (code: string): string => {
  if (code.length === 6) {
    return `${code.substring(0, 3)} ${code.substring(3, 6)}`.toUpperCase();
  }
  else {
    return code.toUpperCase();
  }
};

export const formatAge = (dob: Date): string => {
  const convertedDob = addMinutes(dob, dob.getTimezoneOffset());
  if (isFuture(convertedDob)) {
    return "Future birthdate";
  }

  // format according to https://www.gregoryschmidt.ca/writing/patient-age-display-ehr-conventions
  const curTime = new Date();
  // < 2 hours old
  if (isAfter(convertedDob, subHours(curTime, 2))) {
    const unitAValue = differenceInMinutes(curTime, convertedDob);
    return `${unitAValue} min`;
  }
  // < 2 days old
  else if (isAfter(convertedDob, subDays(curTime, 2))) {
    const unitAValue = differenceInHours(curTime, convertedDob);
    return `${unitAValue} hr`;
  }
  // < 4 weeks old
  else if (isAfter(convertedDob, subWeeks(curTime, 4))) {
    const unitAValue = differenceInDays(curTime, convertedDob);
    return `${unitAValue} day`;
  }
  // < 1 year old
  else if (isAfter(convertedDob, subYears(curTime, 1))) {
    const unitAValue = differenceInWeeks(curTime, convertedDob);
    const unitBValue = differenceInDays(subWeeks(curTime, unitAValue), convertedDob);
    return `${unitAValue} wk${unitBValue ? ` ${unitBValue} d` : ""}`;
  }
  // < 2 years old
  else if (isAfter(convertedDob, subYears(curTime, 2))) {
    const unitAValue = differenceInMonths(curTime, convertedDob);
    const unitBValue = differenceInDays(subMonths(curTime, unitAValue), convertedDob);
    return `${unitAValue} mo${unitBValue ? ` ${unitBValue} d` : ""}`;
  }
  // < 18 years old
  else if (isAfter(convertedDob, subYears(curTime, 18))) {
    const unitAValue = differenceInYears(curTime, convertedDob);
    const unitBValue = differenceInMonths(subYears(curTime, unitAValue), convertedDob);
    return `${unitAValue} yr${unitBValue ? ` ${unitBValue} mo` : ""}`;
  }
  // >= 18 years old
  else {
    return `${differenceInYears(curTime, convertedDob)} yr`;
  }
};

export const formatDate = (date: Date, options?: { format?: string; timeZone?: string }): string => {
  return formatFNS(new TZDateMini(date, options?.timeZone), options?.format ?? "MMM dd, yyyy");
};

export const formatAddress = (
  address: {
    streetAddress: string | null;
    streetAddress2?: string | null;
    city: string | null;
    province: string | null;
    postal: string | null;
  },
  isMultiline: boolean = false
): string => {
  let composedAddress = address.streetAddress ?? "...";
  if (address.streetAddress2) {
    composedAddress = `${composedAddress}, ${address.streetAddress2}`;
  }
  composedAddress = `${composedAddress}${isMultiline ? "\n" : " "}${address.city ?? "..."}, ${address.province ? userProvinceMap[address.province] : "..."} ${formatPostalZipCode(address.postal ?? "...")}`;
  return composedAddress;
};

export const formatHealthCard = (healthCard: string, insurer: string): string => {
  const insuranceData: InsuranceData[] | undefined = queryClient.getQueryData(["insuranceData"]);
  const insuranceObject = insuranceData?.find((item) => item.label === insurer);
  if (insuranceObject?.healthCardMask && healthCard.length === insuranceObject.healthCardMask.match(/x/g)?.length) {
    return healthCard
      .split("")
      .reduce((prev, char) => prev.replace("x", char), insuranceObject.healthCardMask)
      .toUpperCase();
  }

  return healthCard.toUpperCase();
};

export const formatName = (
  person?: {
    firstName?: string | null;
    lastName?: string | null;
    designations?: string | null;
    sex?: number | null;
    practitionerType?: PractitionerType;
    preferredName?: string | null;
    salutation?: string | null;
  } | null,
  formatType: "salutation" | "first_last" | "last_first" | "practitioner" | "signature" | "initials" = "last_first"
): string => {
  if (!person || (!person.firstName && !person.lastName && !person.preferredName)) {
    return "";
  }
  let salutation = "";
  if (person.salutation) {
    salutation = `${person.salutation} `;
  }
  switch (formatType) {
    case "first_last":
      if (!person.firstName || !person.lastName) {
        return `${person.firstName ?? person.lastName}${person.preferredName ? ` (${person.preferredName})` : ""}`;
      }
      return `${person.firstName} ${person.lastName}${person.preferredName ? ` (${person.preferredName})` : ""}`;
    case "last_first":
      if (!person.firstName || !person.lastName) {
        return `${person.firstName ?? person.lastName}${person.preferredName ? ` (${person.preferredName})` : ""}`;
      }
      return `${person.lastName}, ${person.firstName}${person.preferredName ? ` (${person.preferredName})` : ""}`;
    case "practitioner":
      if (!person.firstName || !person.lastName) {
        return `${salutation}${person.firstName ?? person.lastName}${person.designations ? ", " : ""}${person.designations?.replaceAll("\n", ", ") ?? ""}`;
      }
      return `${salutation}${person.firstName} ${person.lastName}${person.designations ? ", " : ""}${person.designations?.replaceAll("\n", ", ") ?? ""}`;
    case "signature":
      if (!person.firstName || !person.lastName) {
        return `${salutation}${person.firstName ?? person.lastName}${person.designations ? "\n" : ""}${person.designations ?? ""}`;
      }
      return `${salutation}${person.firstName} ${person.lastName}${person.designations ? "\n" : ""}${person.designations ?? ""}`;
    case "initials":
      if (!person.firstName || !person.lastName) {
        return (person.firstName ?? person.lastName)!.charAt(0);
      }
      return `${person.firstName.charAt(0)}${person.lastName.charAt(0)}`;
    case "salutation":
      if (!salutation) {
        salutation = person.sex === 0 ? "Mr. " : person.sex === 1 ? "Ms. " : "Mx. ";
      }
      if (!person.firstName || !person.lastName) {
        return `${salutation}${person.firstName ?? person.lastName}`;
      }
      return `${salutation}${person.firstName} ${person.lastName}`;
  }
};

export const formatGender = (gender?: number | null): string => {
  switch (gender) {
    case 0:
      return "Male";
    case 1:
      return "Female";
    case 2:
      return "Non-Binary";
    default:
      return "No Gender Specified";
  }
};

export const sanitizeString = (s: string, keepList: string[]): string => {
  const filter = keepList.reduce(
    (prev, charset) => `${prev}${CHARACTER_SETS.hasOwnProperty(charset) ? CHARACTER_SETS[charset as keyof typeof CHARACTER_SETS] : ""}`,
    ""
  );
  if (filter.length === 0) {
    return s;
  }
  return s.replaceAll(new RegExp(`[^${filter}]`, "g"), "");
};
