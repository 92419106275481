import { isNil } from "lodash";
import { Dialog as SCNDialog, DialogTitle, DialogContent, DialogTrigger, DialogHeader, DialogFooter } from "@/components/ui/dialog";
import { Button as SCNButton } from "@/components/ui/button";
import { Input as SCNInput } from "@/components/ui/input";
import { Checkbox as SCNCheckbox } from "@/components/ui/checkbox";
import { DatePicker as SCNDatePicker } from "@/components/ui/datePicker";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Combobox, ComboItem } from "@/components/ui/combobox";
import { SubmitErrorHandler, SubmitHandler, UseFormReturn, useFieldArray, useForm, useFormState } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Icon from "@mdi/react";
import { PhysicianDropdown } from "@/components/physicianDropdown/physicianDropdown";
import { PharmacyDropdown } from "@/components/pharmacyDropdown/pharmacyDropdown";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { selectedPatientAtom } from "@/lib/states/globalStates";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { userProvinceOptions } from "@/lib/types/constants";
import { mdiLiquidSpot } from "@mdi/js";
import patientAPI from "@/api/patientAPI";
import { EMRPatient } from "@/lib/types/patient";
import { toast } from "sonner";
import dataAPI from "@/api/dataAPI";
export const patientDetailsSchema = z.object({
  firstName: z.string().min(1, {
    message: "First name is required"
  }),
  lastName: z.string().min(1, {
    message: "Last name is required"
  }),
  preferredName: z.string().nullable(),
  birth: z.date().nullable(),
  sex: z.number().nullable(),
  insurer: z.string().nullable(),
  healthCardNumber: z.string().nullable(),
  healthCardExpiryDate: z.date().nullable(),
  email: z.string().nullable(),
  homePhone: z.string().nullable(),
  cellPhone: z.string().nullable(),
  streetAddress: z.string().nullable(),
  streetAddress2: z.string().nullable(),
  city: z.string().nullable(),
  province: z.string().nullable(),
  postal: z.string().nullable(),
  patientReferringDoctorAndLocationIds: z.string().nullable(),
  patientFamilyDoctorAndLocationIds: z.string().nullable(),
  familyPhysicianNotificationStatus: z.boolean().nullable(),
  pharmacyId: z.number().nullable(),
  pharmacyDeliveryStatus: z.boolean().nullable()
});
interface EditPatientDetailsDialogProps {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}
export const EditPatientDetailsDialog = ({
  openDialog,
  setOpenDialog
}: EditPatientDetailsDialogProps) => {
  const [selectedPatient, setSelectedPatient] = useAtom(selectedPatientAtom);
  const [modifiedState, setModifiedState] = useState(false);
  const {
    data: insuranceData
  } = useQuery({
    queryKey: ["insuranceData"],
    queryFn: dataAPI.getInsuranceData,
    staleTime: Infinity,
    placeholderData: []
  });
  const patientDetailsForm = useForm<z.infer<typeof patientDetailsSchema>>({
    resolver: zodResolver(patientDetailsSchema),
    values: {
      firstName: selectedPatient?.firstName ?? "",
      lastName: selectedPatient?.lastName ?? "",
      preferredName: selectedPatient?.preferredName ?? null,
      birth: selectedPatient?.birth ?? null,
      sex: selectedPatient?.sex ?? null,
      insurer: selectedPatient?.insurer ?? null,
      healthCardNumber: selectedPatient?.healthCardNumber ?? null,
      healthCardExpiryDate: selectedPatient?.healthCardExpiryDate ?? null,
      email: selectedPatient?.email ?? null,
      homePhone: selectedPatient?.homePhone ?? null,
      cellPhone: selectedPatient?.cellPhone ?? null,
      streetAddress: selectedPatient?.streetAddress ?? null,
      streetAddress2: selectedPatient?.streetAddress2 ?? null,
      city: selectedPatient?.city ?? null,
      province: selectedPatient?.province ?? null,
      postal: selectedPatient?.postal ?? null,
      patientReferringDoctorAndLocationIds: !isNil(selectedPatient?.patientReferringDoctorId) && !isNil(selectedPatient?.patientReferringDoctorLocationId) ? `${selectedPatient?.patientReferringDoctorId},${selectedPatient?.patientReferringDoctorLocationId}` : null,
      patientFamilyDoctorAndLocationIds: !isNil(selectedPatient?.patientFamilyDoctorId) && !isNil(selectedPatient?.patientFamilyDoctorLocationId) ? `${selectedPatient?.patientFamilyDoctorId},${selectedPatient?.patientFamilyDoctorLocationId}` : null,
      familyPhysicianNotificationStatus: selectedPatient?.familyPhysicianNotificationStatus ?? null,
      pharmacyId: selectedPatient?.pharmacyId ?? null,
      pharmacyDeliveryStatus: selectedPatient?.pharmacyDeliveryStatus ?? null
    }
  });
  const updatePatientInfoMutation = useMutation({
    mutationFn: (patientInfo: EMRPatient) => patientAPI.updatePatient(patientInfo),
    onSuccess: data => {
      setSelectedPatient(data);
      toast.success("Patient info updated.");
      setOpenDialog(false);
    }
  });
  const submitForm: SubmitHandler<z.infer<typeof patientDetailsSchema>> = data => {
    updatePatientInfoMutation.mutate({
      ...selectedPatient!,
      ...{
        firstName: data.firstName,
        lastName: data.lastName,
        preferredName: data.preferredName,
        birth: data.birth ?? null,
        sex: data.sex,
        insurer: data.insurer,
        healthCardNumber: data.healthCardNumber,
        healthCardExpiryDate: data.healthCardExpiryDate ?? null,
        email: data.email || null,
        homePhone: data.homePhone,
        cellPhone: data.cellPhone,
        streetAddress: data.streetAddress,
        streetAddress2: data.streetAddress2,
        city: data.city,
        province: data.province,
        postal: data.postal,
        patientReferringDoctorAndLocationIds: data.patientReferringDoctorAndLocationIds,
        patientFamilyDoctorAndLocationIds: data.patientFamilyDoctorAndLocationIds,
        familyPhysicianNotificationStatus: data.familyPhysicianNotificationStatus,
        pharmacyId: data.pharmacyId,
        pharmacyDeliveryStatus: data.pharmacyDeliveryStatus
      }
    } satisfies EMRPatient as EMRPatient);
  };
  const onInvalid: SubmitErrorHandler<z.infer<typeof patientDetailsSchema>> = e => {
    console.error(e);
    toast.error(`Cannot save due to error(s) in patient details input.`);
  };
  useEffect(() => {
    return () => {
      if (!openDialog) {
        patientDetailsForm.reset();
        setModifiedState(false);
      }
    };
  }, [openDialog]);
  return <>
      <SCNDialog open={openDialog} onOpenChange={setOpenDialog} data-sentry-element="SCNDialog" data-sentry-source-file="editPatientDetailsDialog.tsx">
        <DialogContent overlayClass="bg-black/30" className="min-w-[600px]" data-sentry-element="DialogContent" data-sentry-source-file="editPatientDetailsDialog.tsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="editPatientDetailsDialog.tsx">
            <DialogTitle className="" data-sentry-element="DialogTitle" data-sentry-source-file="editPatientDetailsDialog.tsx">Quick Edit Patient Info</DialogTitle>
          </DialogHeader>
          <Form {...patientDetailsForm} data-sentry-element="Form" data-sentry-source-file="editPatientDetailsDialog.tsx">
            <form className="flex h-full w-full flex-col gap-1 [@media(min-height:1000px)]:gap-2">
              <div className="flex w-full justify-between gap-4">
                <FormField control={patientDetailsForm.control} name="firstName" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex w-full items-center gap-2">
                      <FormLabel className="text-nowrap">
                        <span className="text-destructive">* </span>First Name
                      </FormLabel>
                      <FormControl>
                        <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

                <FormField control={patientDetailsForm.control} name="lastName" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex w-full items-center gap-2">
                      <FormLabel className="text-nowrap">
                        <span className="text-destructive">* </span>Last Name
                      </FormLabel>
                      <FormControl>
                        <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />
              </div>

              <div className="flex w-full items-center justify-between gap-4">
                <FormField control={patientDetailsForm.control} name="birth" render={({
                field: {
                  value,
                  onChange,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex items-center gap-2">
                      <FormLabel className="text-nowrap">Date of Birth</FormLabel>
                      <FormControl>
                        <SCNDatePicker selectorContainerClassName="text-component-fg bg-primary disabled:cursor-default h-6 [@media(min-height:1000px)]:h-8 flex w-[150px] !mt-0" {...fields} date={value} onDateChange={value => {
                    onChange(value);
                    setModifiedState(true);
                  }} showYearNavigation showDecadeNavigation selectableDates timeZone="UTC" />
                      </FormControl>

                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

                <FormField control={patientDetailsForm.control} name="sex" render={({
                field: {
                  value,
                  onChange,
                  ...fields
                },
                fieldState,
                formState
              }) => {
                const genderData: ComboItem[] = [{
                  name: "Male",
                  value: "0"
                }, {
                  name: "Female",
                  value: "1"
                }, {
                  name: "Non-Binary",
                  value: "2"
                }];
                return <FormItem className="flex w-full items-center gap-2">
                        <FormLabel className="shrink-0">Gender</FormLabel>
                        <FormControl>
                          <Combobox containerClassName="flex-1 !mt-0 h-6 [@media(min-height:1000px)]:h-8" popoverTriggerClassName="w-full text-sm h-6 [@media(min-height:1000px)]:h-8 items-center flex" popoverContentClassName="min-w-[200px]" dropdownHeight={118} placeholder="Choose a gender" currentValue={value?.toString() ?? null} onSelect={value => {
                      onChange(parseInt(value));
                      setModifiedState(true);
                    }} onDeselect={() => {
                      onChange(null);
                      setModifiedState(true);
                    }} data={genderData} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>;
              }} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />
              </div>

              <FormField control={patientDetailsForm.control} name="insurer" render={({
              field: {
                value,
                onChange,
                ...fields
              },
              fieldState,
              formState
            }) => {
              return <FormItem className="flex w-full items-center gap-2">
                      <FormLabel className="shrink-0">Insurer</FormLabel>
                      <FormControl>
                        <Combobox containerClassName="!mt-0 w-full h-6 [@media(min-height:1000px)]:h-8" popoverTriggerClassName="w-full text-sm h-6 [@media(min-height:1000px)]:h-8 items-center flex" popoverContentClassName="min-w-[200px]" allowRemove placeholder="Choose a province" currentValue={value?.toString() ?? ""} onSelect={value => {
                    onChange(value);
                    setModifiedState(true);
                  }} onDeselect={() => {
                    onChange("");
                    setModifiedState(true);
                  }} data={insuranceData!.map(item => ({
                    name: item.label,
                    value: item.label
                  })) satisfies ComboItem[] as ComboItem[]} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>;
            }} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

              <div className="flex justify-between gap-4">
                <FormField control={patientDetailsForm.control} name="healthCardNumber" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex w-2/3 items-center gap-2">
                      <FormLabel className="shrink-0">Health Card Number</FormLabel>
                      <FormControl>
                        <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />
              </div>

              <div className="flex w-full justify-between gap-4">
                <FormField control={patientDetailsForm.control} name="healthCardExpiryDate" render={({
                field: {
                  value,
                  onChange,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex items-center gap-2">
                      <FormLabel className="shrink-0">Expiration Date</FormLabel>
                      <FormControl>
                        <SCNDatePicker selectorContainerClassName="text-component-fg bg-primary disabled:cursor-default h-6 [@media(min-height:1000px)]:h-8 flex w-[150px] !mt-0" {...fields} date={value} onDateChange={value => {
                    onChange(value);
                    setModifiedState(true);
                  }} showYearNavigation showDecadeNavigation selectableDates timeZone="UTC" />
                      </FormControl>

                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

                <FormField control={patientDetailsForm.control} name="email" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex flex-1 items-center gap-2">
                      <FormLabel className="shrink-0">Email</FormLabel>
                      <FormControl>
                        <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />
              </div>

              <div className="flex w-full justify-between gap-4">
                <FormField control={patientDetailsForm.control} name="homePhone" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex items-center gap-2">
                      <FormLabel className="shrink-0">Home Phone</FormLabel>
                      <FormControl>
                        <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

                <FormField control={patientDetailsForm.control} name="cellPhone" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex items-center gap-2">
                      <FormLabel className="shrink-0">Cell Phone</FormLabel>
                      <FormControl>
                        <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />
              </div>

              <div className="my-1.5 flex h-7 items-center bg-gray-300 pl-2">ADDRESS</div>

              <FormField control={patientDetailsForm.control} name="streetAddress" render={({
              field: {
                onChange,
                value,
                ...fields
              },
              fieldState,
              formState
            }) => <FormItem className="flex w-full items-center gap-2">
                    <FormLabel className="shrink-0">Address</FormLabel>
                    <FormControl>
                      <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                  onChange(e);
                  setModifiedState(true);
                }} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

              <FormField control={patientDetailsForm.control} name="streetAddress2" render={({
              field: {
                onChange,
                value,
                ...fields
              },
              fieldState,
              formState
            }) => <FormItem className="flex w-full items-center gap-2">
                    <FormLabel className="shrink-0">Address 2</FormLabel>
                    <FormControl>
                      <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                  onChange(e);
                  setModifiedState(true);
                }} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

              <div className="flex w-full justify-between gap-4">
                <FormField control={patientDetailsForm.control} name="city" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex w-full items-center gap-2">
                      <FormLabel className="shrink-0">City</FormLabel>
                      <FormControl>
                        <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

                <FormField control={patientDetailsForm.control} name="province" render={({
                field: {
                  value,
                  onChange,
                  ...fields
                },
                fieldState,
                formState
              }) => {
                return <FormItem className="flex w-full items-center gap-2">
                        <FormLabel className="shrink-0">Province</FormLabel>
                        <FormControl>
                          <Combobox containerClassName="!mt-0 w-full h-6 [@media(min-height:1000px)]:h-8" popoverTriggerClassName="w-full text-sm h-6 [@media(min-height:1000px)]:h-8 items-center flex" popoverContentClassName="min-w-[200px]" allowRemove placeholder="Choose a province" currentValue={value ?? ""} onSelect={value => {
                      onChange(value);
                      setModifiedState(true);
                    }} onDeselect={() => {
                      onChange("");
                      setModifiedState(true);
                    }} data={userProvinceOptions.map(item => ({
                      name: item,
                      value: item
                    })) satisfies ComboItem[] as ComboItem[]} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>;
              }} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />
              </div>

              <FormField control={patientDetailsForm.control} name="postal" render={({
              field: {
                onChange,
                value,
                ...fields
              },
              fieldState,
              formState
            }) => <FormItem className="flex w-full items-center gap-2">
                    <FormLabel className="shrink-0">Postal Code</FormLabel>
                    <FormControl>
                      <SCNInput variant="form" className="!mt-0 h-6 [@media(min-height:1000px)]:h-8" {...fields} value={value ?? ""} onChange={e => {
                  onChange(e);
                  setModifiedState(true);
                }} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

              <FormField control={patientDetailsForm.control} name="patientReferringDoctorAndLocationIds" render={({
              field: {
                onChange,
                value,
                ...fields
              },
              fieldState,
              formState
            }) => <FormItem className="flex w-full items-center gap-2">
                    <FormLabel className="shrink-0">Referring Provider</FormLabel>
                    <FormControl>
                      <PhysicianDropdown containerClassName="h-6 [@media(min-height:1000px)]:h-8 !mt-0 w-full" popoverTriggerClassName="w-full text-sm h-6 [@media(min-height:1000px)]:h-8 items-center flex" physicianDropdownKey="referringProvider" physicianId={!isNil(value?.split(",")?.at(0)) ? Number(value?.split(",")?.at(0)) : null} physicianLocationId={!isNil(value?.split(",")?.at(1)) ? value?.split(",")?.at(1) : null} onSelectPhysician={singlePhysicianLocation => {
                  onChange(singlePhysicianLocation?.physicianAndLocationId);
                  setModifiedState(true);
                }} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

              <FormField control={patientDetailsForm.control} name="patientFamilyDoctorAndLocationIds" render={({
              field: {
                onChange,
                value,
                ...fields
              },
              fieldState,
              formState
            }) => <FormItem className="flex w-full items-center gap-2">
                    <FormLabel className="shrink-0">Family Provider</FormLabel>
                    <FormControl>
                      <PhysicianDropdown containerClassName="h-6 [@media(min-height:1000px)]:h-8 !mt-0 w-full" popoverTriggerClassName="w-full text-sm h-6 [@media(min-height:1000px)]:h-8 items-center flex" physicianDropdownKey="familyProvider" physicianId={!isNil(value?.split(",")?.at(0)) ? Number(value?.split(",")?.at(0)) : null} physicianLocationId={!isNil(value?.split(",")?.at(1)) ? value?.split(",")?.at(1) : null} onSelectPhysician={singlePhysicianLocation => {
                  onChange(singlePhysicianLocation?.physicianAndLocationId);
                  setModifiedState(true);
                }} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

              <FormField control={patientDetailsForm.control} name="pharmacyId" render={({
              field: {
                onChange,
                value,
                ...fields
              },
              fieldState,
              formState
            }) => <FormItem className="flex w-full items-center gap-2">
                    <FormLabel className="shrink-0">Pharmacy</FormLabel>
                    <FormControl>
                      <PharmacyDropdown containerClassName="h-6 [@media(min-height:1000px)]:h-8 !mt-0 w-full" popoverTriggerClassName="w-full text-sm h-6 [@media(min-height:1000px)]:h-8 items-center flex" pharmacyId={value ?? null} onSelectPharmacy={pharmacy => {
                  onChange(pharmacy?.id);
                  setModifiedState(true);
                }} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

              <div className="flex justify-around py-1">
                <FormField control={patientDetailsForm.control} name="familyPhysicianNotificationStatus" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex items-center gap-2">
                      <FormLabel htmlFor="familyPhysicianNotificationStatus" className="shrink-0 cursor-pointer">
                        Notify Family Provider
                      </FormLabel>
                      <FormControl>
                        <SCNCheckbox id="familyPhysicianNotificationStatus" className="!mt-0" checked={value ?? undefined} onCheckedChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />

                <FormField control={patientDetailsForm.control} name="pharmacyDeliveryStatus" render={({
                field: {
                  onChange,
                  value,
                  ...fields
                },
                fieldState,
                formState
              }) => <FormItem className="flex items-center gap-2">
                      <FormLabel htmlFor="pharmacyDeliveryStatus" className="shrink-0 cursor-pointer">
                        Deliver Prescriptions
                      </FormLabel>
                      <FormControl>
                        <SCNCheckbox id="pharmacyDeliveryStatus" className="!mt-0" checked={value ?? undefined} onCheckedChange={e => {
                    onChange(e);
                    setModifiedState(true);
                  }} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="editPatientDetailsDialog.tsx" />
              </div>
            </form>
          </Form>

          <DialogFooter className="flex w-full items-center justify-between gap-2.5 sm:justify-between" data-sentry-element="DialogFooter" data-sentry-source-file="editPatientDetailsDialog.tsx">
            <div className="flex items-center gap-2.5">
              {process.env.NEXT_PUBLIC_ENV === "dev" && <SCNButton type="button" onClick={() => {
              console.log(`selectedPatient: `, selectedPatient);
              console.log(`patientDetailsForm: `, patientDetailsForm.getValues());
              console.log(`patientFamilyDoctorAndLocationIds:`), patientDetailsForm.getValues("patientFamilyDoctorAndLocationIds");
            }} variant="secondary">
                  Debug
                </SCNButton>}
            </div>
            <div className="flex items-center gap-2.5">
              {modifiedState && <SCNTooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center font-sans font-normal text-red-300">
                      <Icon path={mdiLiquidSpot} size={1} />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>There are unsaved changes</TooltipContent>
                </SCNTooltip>}

              <SCNButton type="button" onClick={() => {
              setOpenDialog(false);
            }} variant="outline" data-sentry-element="SCNButton" data-sentry-source-file="editPatientDetailsDialog.tsx">
                Cancel
              </SCNButton>
              <SCNButton onClick={patientDetailsForm.handleSubmit(submitForm, onInvalid)} data-sentry-element="SCNButton" data-sentry-source-file="editPatientDetailsDialog.tsx">Update</SCNButton>
            </div>
          </DialogFooter>
        </DialogContent>
      </SCNDialog>
    </>;
};