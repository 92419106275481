import { HomeButton } from "./homeButton";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils/cssUtils";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";
export const NotDoctorButUserMenu = () => {
  const pathname = usePathname();
  const activatedLinkClasses = "bg-nav-selected";
  return <div data-sentry-component="NotDoctorButUserMenu" data-sentry-source-file="nonDoctorButUserMenu.tsx">
      <HomeButton data-sentry-element="HomeButton" data-sentry-source-file="nonDoctorButUserMenu.tsx" />

      <div className="mt-8 flex flex-col items-center justify-center gap-2.5">
        <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="nonDoctorButUserMenu.tsx">
          <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="nonDoctorButUserMenu.tsx">
            <Link href={`/appointments`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/appointments" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="nonDoctorButUserMenu.tsx">
              <Image src={`${basePath}/Scheduler.svg`} alt="Appointments" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="nonDoctorButUserMenu.tsx" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="nonDoctorButUserMenu.tsx">Appointments</TooltipContent>
        </SCNTooltip>
        <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="nonDoctorButUserMenu.tsx">
          <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="nonDoctorButUserMenu.tsx">
            <Link href={`/taskNotes`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/taskNotes" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="nonDoctorButUserMenu.tsx">
              <Image src={`${basePath}/Communications.svg`} alt="Communications" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="nonDoctorButUserMenu.tsx" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="nonDoctorButUserMenu.tsx">Communications</TooltipContent>
        </SCNTooltip>
        <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="nonDoctorButUserMenu.tsx">
          <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="nonDoctorButUserMenu.tsx">
            <Link href={`/patientManagement`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/patientManagement" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="nonDoctorButUserMenu.tsx">
              <Image src={`${basePath}/Patient Details.svg`} alt="Patient Management" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="nonDoctorButUserMenu.tsx" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="nonDoctorButUserMenu.tsx">Patient Management</TooltipContent>
        </SCNTooltip>
        <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="nonDoctorButUserMenu.tsx">
          <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="nonDoctorButUserMenu.tsx">
            <Link href={`/clinicalChart`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/clinicalChart" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="nonDoctorButUserMenu.tsx">
              <Image src={`${basePath}/Virtual Chart.svg`} alt="Clinical Chart" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="nonDoctorButUserMenu.tsx" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="nonDoctorButUserMenu.tsx">Clinical Chart</TooltipContent>
        </SCNTooltip>
        <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="nonDoctorButUserMenu.tsx">
          <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="nonDoctorButUserMenu.tsx">
            <Link href={`/billing`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/billing" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="nonDoctorButUserMenu.tsx">
              <Image src={`${basePath}/Billing.svg`} alt="Billing" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="nonDoctorButUserMenu.tsx" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="nonDoctorButUserMenu.tsx">Billing</TooltipContent>
        </SCNTooltip>
        <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="nonDoctorButUserMenu.tsx">
          <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="nonDoctorButUserMenu.tsx">
            <Link href={`/documentManager`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/documentManager" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="nonDoctorButUserMenu.tsx">
              <Image src={`${basePath}/Documents.svg`} alt="Document Manager" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="nonDoctorButUserMenu.tsx" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="nonDoctorButUserMenu.tsx">Document Manager</TooltipContent>
        </SCNTooltip>
        <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="nonDoctorButUserMenu.tsx">
          <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="nonDoctorButUserMenu.tsx">
            <Link href={`/addressBook`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/addressBook" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="nonDoctorButUserMenu.tsx">
              <Image src={`${basePath}/Contacts.svg`} alt="Address Book" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="nonDoctorButUserMenu.tsx" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="nonDoctorButUserMenu.tsx">Address Book</TooltipContent>
        </SCNTooltip>
      </div>
    </div>;
};