import Link from "next/link";
import Image from "next/image";
import { Dispatch, SetStateAction } from "react";
import Icon from "@mdi/react";
import { mdiAccountGroupOutline } from "@mdi/js";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils/cssUtils";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { signOut } from "next-auth/react";
import useUserQuery from "@/lib/utils/hooks/useUserQuery";
const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";
export const NavMenuBottom = () => {
  const {
    data: user
  } = useUserQuery();
  const pathname = usePathname();
  const activatedLinkClasses = "bg-nav-selected";
  return <div className="flex flex-col items-center justify-center gap-2.5" data-sentry-component="NavMenuBottom" data-sentry-source-file="navMenuBottom.tsx">
      <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="navMenuBottom.tsx">
        <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="navMenuBottom.tsx">
          <Link href={`/templaterManager`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/templaterManager" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="navMenuBottom.tsx">
            <Image src={`${basePath}/TemplaterManager.svg`} alt="Templater Manager" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="navMenuBottom.tsx" />
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="navMenuBottom.tsx">Template Manager</TooltipContent>
      </SCNTooltip>
      <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="navMenuBottom.tsx">
        <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="navMenuBottom.tsx">
          <Link href={`/AIVerification`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/AIVerification" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="navMenuBottom.tsx">
            <Image src={`${basePath}/AI Review.svg`} alt="AI Verification" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="navMenuBottom.tsx" />
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="navMenuBottom.tsx">AI Verification</TooltipContent>
      </SCNTooltip>
      {user?.type === "admin" && <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link href={`/registerNewUserPage`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/registerNewUserPage" && activatedLinkClasses)}>
              <Image src={`${basePath}/Register.svg`} alt="Register New User" height={30} width={30} />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Register New User</TooltipContent>
        </SCNTooltip>}
      {user?.viewAddUser && <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link href={`/userManagement`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/userManagement" && activatedLinkClasses)}>
              <Icon path={mdiAccountGroupOutline} size={1.2} className="text-white" />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">User Management</TooltipContent>
        </SCNTooltip>}
      <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="navMenuBottom.tsx">
        <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="navMenuBottom.tsx">
          <Link href={`/userProfile`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/userProfile" && activatedLinkClasses)} data-sentry-element="Link" data-sentry-source-file="navMenuBottom.tsx">
            <Image src={`${basePath}/User Profile.svg`} alt="User Profile" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="navMenuBottom.tsx" />
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="navMenuBottom.tsx">User Profile</TooltipContent>
      </SCNTooltip>
      {false && <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link href={`/settings`} className={cn(`hover:!bg-nav-hover flex justify-center rounded-md px-5 py-2`, pathname === "/settings" && activatedLinkClasses)}>
              <Image src={`${basePath}/Settings.svg`} alt="Settings" height={30} width={30} />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Settings</TooltipContent>
        </SCNTooltip>}
      <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="navMenuBottom.tsx">
        <TooltipTrigger className="flex w-full items-center justify-center" data-sentry-element="TooltipTrigger" data-sentry-source-file="navMenuBottom.tsx">
          <div className="hover:bg-nav-hover mb-3 rounded-md px-5 py-2 hover:cursor-pointer" onClick={() => {
          localStorage.removeItem("justLoggedIn");
          signOut({
            callbackUrl: "/",
            redirect: true
          });
        }}>
            <Image src={`${basePath}/Logout.svg`} alt="Logout" height={30} width={30} data-sentry-element="Image" data-sentry-source-file="navMenuBottom.tsx" />
          </div>
        </TooltipTrigger>
        <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="navMenuBottom.tsx">Logout</TooltipContent>
      </SCNTooltip>
    </div>;
};