import { DocumentTypesDropdownDataModel, GenericDropdownDataModel, InsuranceData } from "@/lib/types/constants";
import customFetch from "./customFetch";
import { BillingTimeUnitRange, BillingUnitFee } from "@/lib/types/billing";

const getConcernsDropdownData = async (): Promise<GenericDropdownDataModel[]> => {
  const { dropdownData } = await customFetch(`/data/concerns`);
  return dropdownData;
};

const getDocumentTypesDropdownData = async (): Promise<DocumentTypesDropdownDataModel[]> => {
  const { dropdownData } = await customFetch(`/data/documenttypes`);
  return dropdownData;
};

const getOrderFormsData = async (): Promise<GenericDropdownDataModel[]> => {
  const { orderFormsData } = await customFetch(`/data/orderforms`);
  return orderFormsData;
};

const getBillingUnitFees = async (): Promise<BillingUnitFee[]> => {
  const { unitFees } = await customFetch(`/data/billingUnitFees`);
  return unitFees;
};

const getBillingTimeUnitRanges = async (): Promise<BillingTimeUnitRange[]> => {
  const { timeUnitRanges } = await customFetch(`/data/billingTimeUnitRanges`);
  return timeUnitRanges;
};

const getBillingClaimStatusesDropdownData = async (): Promise<GenericDropdownDataModel[]> => {
  const { dropdownData } = await customFetch(`/data/billingClaimStatuses`);
  return dropdownData;
};

const getInsuranceData = async (): Promise<InsuranceData[]> => {
  const { insuranceData } = await customFetch(`/data/insuranceData`);
  return insuranceData;
}

const dataAPI = {
  getConcernsDropdownData,
  getDocumentTypesDropdownData,
  getOrderFormsData,
  getBillingUnitFees,
  getBillingTimeUnitRanges,
  getBillingClaimStatusesDropdownData,
  getInsuranceData,
};

export default dataAPI;
